<template > 
	 
	<ul v-if="items">
		 
		 			<template v-for="(item,i) of items">  
 				<li v-if="visible(item) && !item.separator && visibleL(item) " :key="i" :class="[{'active-menuitem': activeIndex === i && !item.to && !item.disabled}]" role="none">
					
					<div v-if="item.items && root===true" class='arrow'></div>
					<router-link v-if="item.to" :to="item.to" :class="[item.class, 'p-ripple',{'active-route': activeIndex === i, 'p-disabled': item.disabled}]" :style="item.style"
								@click="onMenuItemClick($event,item,i)" :target="item.target" exact role="menuitem" v-ripple>
						<i :class="item.icon"></i>
						<span>{{item.label}}</span> 
						<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
						<span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span>
					</router-link>
					<a v-if="!item.to" :href="item.url||'#'" :style="item.style" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]"
						@click="onMenuItemClick($event,item,i)" :target="item.target" role="menuitem" v-ripple>
						<i :class="item.icon"></i>
						<span>{{item.label}}</span>
						<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
						<span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span>
					</a>
					<transition name="layout-submenu-wrapper">
						<AppSubmenu v-show="activeIndex === i" :items="visible(item) && item.items" @menuitem-click="$emit('menuitem-click', $event)"></AppSubmenu>
					</transition>
				
				</li>
				<li class="p-menu-separator" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i" role="separator"></li>
		 
		</template> 
	</ul>
	 
</template>
<script>  
import Cookies from "js-cookie"
window.Cookies = Cookies
export default {
	name: 'AppSubmenu',
	props: {
		items: Array,
		root: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			activeIndex : null,
			datapermisos:null,
			useconver:null,
			userdata:null,
			nombre:null,
			visblep:false,
		}
	}, 
	 
	mounted(){
		//  this.useconver = Cookies.get("userpers");
		this.useconver=localStorage.getItem('permisos')
		//   console.log('App',this.useconver);

			if(this.useconver ==null || this.useconver == 'null' ){
				this.userdata=null;
				this.nombre=null;	
				
			}else{
				if(this.useconver != null || this.useconver !="null"){
				this.userdata=JSON.parse(this.useconver)
				// console.log(this.userdata);
				// if(this.userdata != null || this.userdata != "null"){
				//  console.log(this.userdata);
				  
				// }
				
				}		
			}
	},
	methods: {
		onMenuItemClick(event, item, index) {
			// console.log(item.disabled)
			if (item.disabled) {
				event.preventDefault();
				return;
            }

            if (!item.to && !item.url) {
                event.preventDefault();
            }

			//execute command
			if (item.command) {
                item.command({originalEvent: event, item: item});
			}

			this.activeIndex = index === this.activeIndex ? null : index;

			this.$emit('menuitem-click', {
				originalEvent: event,
				item: item
			});
		},
		visible(item) {
			//  console.log(item) 
			return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
		},
		visibleL(item){
			let rep=false;
			// for(var i in this.userdata){
				for(var i in this.userdata)
				{
					if(item.label == this.userdata[i].nombre){
						rep=true;
						// console.log(rep=true,item.label,this.userdata[i].nombre);
					}
				}
			 
			// console.log('d',this.userdata);		
			return rep;
		}
	},
	components: {
		"AppSubmenu": this
	}
}
</script>

<style scoped>

</style>
