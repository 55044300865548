import axios from 'axios';

export default class DashboardService {

	getpacientedash() {
		//return axios.get('assets/layout/data/precios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/pacientedash`;
		const url = `/api/pacientedash`;
		return axios.get(url).then(res=>res.data.data);
	}
	

}