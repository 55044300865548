import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';
import Login from './components/Login.vue';

const routes = [

    {
        path: '/',
        name: 'login',
        component: Login,          
         
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/login',
        // redirect: {
            name: "login",
            component: () => import('./components/Login.vue'),
        // }
    },
    {
        path: '/formlayout',
        name: 'formlayout',
        component: () => import('./components/FormLayoutDemo.vue'),
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('./components/InputDemo.vue'),
    },
    {
        path: '/floatlabel',
        name: 'floatlabel',
        component: () => import('./components/FloatLabelDemo.vue'),
    },
    {
        path: '/button',
        name: 'button',
        component: () => import('./components/ButtonDemo.vue'),
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('./components/TableDemo.vue'),
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('./components/ListDemo.vue'),
    },
    {
        path: '/tree',
        name: 'tree',
        component: () => import('./components/TreeDemo.vue'),
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('./components/PanelsDemo.vue'),
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('./components/OverlayDemo.vue'),
    },
    {
        path: '/menu',
        component: () => import('./components/MenuDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue'),
            },
            {
                path: '/menu/seat',
                component: () => import('./components/menu/SeatDemo.vue'),
            },
            {
                path: '/menu/payment',
                component: () => import('./components/menu/PaymentDemo.vue'),
            },
            {
                path: '/menu/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue'),
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('./components/MessagesDemo.vue'),
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('./components/FileDemo.vue'),
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('./components/ChartDemo.vue'),
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('./components/MiscDemo.vue'),
    },
    {
        path: '/display',
        name: 'display',
        component: () => import('./utilities/DisplayDemo.vue'),
    },
    {
        path: '/flexbox',
        name: 'flexbox',
        component: () => import('./utilities/FlexBoxDemo.vue'),
    },
    {
        path: '/text',
        name: 'text',
        component: () => import('./utilities/TextDemo.vue'),
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('./utilities/Icons.vue'),
    },
    {
        path: '/grid',
        name: 'grid',
        component: () => import('./utilities/GridDemo.vue'),
    },
    {
        path: '/spacing',
        name: 'spacing',
        component: () => import('./utilities/SpacingDemo.vue'),
    },
    {
        path: '/elevation',
        name: 'elevation',
        component: () => import('./utilities/ElevationDemo.vue'),
    },
    {
        path: '/typography',
        name: 'typography',
        component: () => import('./utilities/Typography.vue'),
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('./pages/CrudDemo.vue'),
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('./pages/CalendarDemo.vue'),
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue'),
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue'),
    },
    {
        path: '/especialidad',
        name: 'especialidad',
        component: () => import('./pages/Especialidad.vue'),
    },
    {
        path: '/usuario',
        name: 'usuario',
        component: () => import('./pages/Usuario.vue'),
    },
    {
        path: '/precio',
        name: 'precio',
        component: () => import('./pages/Precio.vue'),
    },
    {
        path: '/servicio',
        name: 'servicio',
        component: () => import('./pages/Servicio.vue'),
    },
    {
        path: '/doctor',
        name: 'doctor',
        component: () => import('./pages/Doctor.vue'),
    },
    {
        path: '/paciente',
        name: 'paciente',
        component: () => import('./pages/Paciente.vue'),
    },
    {
        path: '/vendedor',
        name: 'vendedor',
        component: () => import('./pages/Vendedor.vue'),
    },
    {
        path: '/origen',
        name: 'origen',
        component: () => import('./pages/Origen.vue'),
    },
    {
        path: '/tipopadecimiento',
        name: 'tipopadecimiento',
        component: () => import('./pages/TipoPadecimiento.vue'),
    },
    {
        path: '/padecimiento',
        name: 'padecimiento',
        component: () => import('./pages/Padecimiento.vue'),
    },
    {
        path: '/muestra',
        name: 'muestra',
        component: () => import('./pages/Muestra.vue'),
    },
    {
        path: '/estado',
        name: 'estado',
        component: () => import('./pages/Estado.vue'),
    },
    {
        path: '/pais',
        name: 'pais',
        component: () => import('./pages/Pais.vue'),
    },
    {
        path: '/solicitudmuestra',
        name: 'solicitudmuestra',
        component: () => import('./pages/SolicitudMuestra.vue'),
    },
    {
        path: '/enviorecepcionmuestra',
        name: 'enviorecepcionmuestra',
        component: () => import('./pages/EnvioRecepcionMuestra.vue'),
    },
    {
        path: '/procesocultivo',
        name: 'procesocultivo',
        component: () => import('./pages/ProcesoCultivo.vue'),
    },
    {
        path: '/enviocelula',
        name: 'enviocelula',
        component: () => import('./pages/EnvioCelula.vue'),
    },
    {
        path: '/consulta',
        name: 'consulta',
        component: () => import('./pages/Consulta.vue'),
    },
    {
        path: '/situacionfinanciera',
        name: 'situacionfinanciera',
        component: () => import('./pages/SituacionFinanciera.vue'),
    },
    {
        path: '/descuento',
        name: 'descuento',
        component: () => import('./pages/Descuento.vue'),
    },
    {
        path: '/saldopaciente',
        name: 'saldopaciente',
        component: () => import('./pages/SaldoPaciente.vue'),
    },
    {
        path: '/pagosmes',
        name: 'pagosmes',
        component: () => import('./pages/PagosMes.vue'),
    },
    // {
    //     path: '/login',
    //     name: 'Login',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "login" */ '../Login.vue')
    //   }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
