<template>
	<div class="layout-topbar">
		<button  class="p-link layout-menu-button" @click="onMenuToggle">
			<span class="pi pi-bars"></span>
		</button>
		<div class="layout-topbar-icons">
			<button class="p-link">
				<span class="layout-topbar-item-text">Settings</span>
				<span class="layout-topbar-icon pi pi-cog"></span>
			</button>
		</div>
	</div>
</template>

<script>
import Cookies from "js-cookie"
window.Cookies = Cookies


export default {
	data() {
		return {
			 userdata:null,
			 user:null,
        }
	},
	mounted(){
		// this.userdata =Cookies.get( "user" );

		// if(this.userdata ==null || this.userdata == 'null' ){
		// 	  console.log(2);			
			 
		// }else{
		// 	this.user=1;			
		// }
		// console.log(this.userdata)
	},
    methods: {
        onMenuToggle(event) {
			
			this.$emit('menu-toggle', event);	
					
        }
    }
}
</script>