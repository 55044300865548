<template>
	<div class="layout-footer">
		<!-- <div> -->
			<img src="assets/layout/images/sg.png" style="width:10%"/>
		<!-- </div> -->
		<!-- <span class="footer-text" style="margin-right: 5px">SG Cells</span> -->
		<!-- <img src="assets/layout/images/logo.svg" alt="sigma" width="80"/> -->
		<span class="footer-text" style="margin-left: 20px;">2020 Derechos Reservados</span>
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style scoped>

</style>