import axios from 'axios';

export default class UsuarioService {

	getUsuarios() {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/usuarios`;
		const url = `/api/usuarios`;
		return axios.get(url).then(res=>res.data.data);
	}
	
	createUsuarios(usuarios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/usuarios`;
		const url = `/api/usuarios`;
		return axios.post(url,usuarios).then(res=>res.data.data);
	}
	
	updateUsuarios(usuarios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/usuarios/${usuarios.id_usuario}`;
		const url = `/api/usuarios/${usuarios.id_usuario}`;
		return axios.put(url,usuarios).then(res=>res.data.data);
	}
	
	deleteUsuarios(usuarios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/usuarios/${usuarios.id_usuario}`;
		const url = `/api/usuarios/${usuarios.id_usuario}`;
		return axios.delete(url).then(res=>res.data.data);
	}
	
	getUsuariosPantallaActivo(usuarios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		// const url = ` https://sam.apicontrol.website/api/api/usuarios/${usuarios.id_usuario}`;
		//const url = ` https://sam.apicontrol.website/api/api/usuariopantallas/${usuarios.id_usuario}`;
		const url = `/api/usuariopantallas/${usuarios.id_usuario}`;
		return axios.get(url).then(res=>res.data.data);
	}
	getUsuariosPantallaInActivo(usuarios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		// const url = ` https://sam.apicontrol.website/api/api/usuarios/${usuarios.id_usuario}`;
		//const url = ` https://sam.apicontrol.website/api/api/usuarioinpantallas/${usuarios.id_usuario}`;
		const url = `/api/usuarioinpantallas/${usuarios.id_usuario}`;
		return axios.get(url).then(res=>res.data.data);
	}
	
	createUsuariosPantallaActivo(usuarios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/usuariopantallas`;
		const url = `/api/usuariopantallas`;
		return axios.post(url,usuarios).then(res=>res.data.data);
	}

	deleteUsuariosPantallaInActivo(usuarios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/usuariopantallas/${usuarios.id_pantallausuario}`;
		const url = `/api/usuariopantallas/${usuarios.id_pantallausuario}`;
		return axios.delete(url).then(res=>res.data.data);
	}

	getUsuariosExport() {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		// const url = ` https://sam.apicontrol.website/api/api/usuarios/${usuarios.id_usuario}`;
		//const url = ` https://sam.apicontrol.website/api/api/usuarioexport`;
		const url = `/api/usuarioexport`;
		return axios.get(url).then(res=>res.data.data);
	}
}