<template>
	<div class="layout-profile" style="padding:0px">
		<div>
			<!-- <img src="assets/layout/images/profile.png" alt="" /> -->
			<img src="assets/layout/images/sg.jpeg" style="width:30%"/>
		</div>
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">{{nombre}}</span>
			<i class="pi pi-fw pi-cog"></i>
		</button>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <!-- <li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>Notificaciones</span><span class="menuitem-badge">2</span></button></li> -->
                <li><button @click="Salir" class="p-link"><i class="pi pi-fw pi-power-off" ></i><span>Salir</span></button></li>
            </ul>
        </transition>
		
	</div>
</template>

<script>
import Cookies from "js-cookie"
window.Cookies = Cookies
	export default {
		data() {
			return {
				expanded: false,
				useconver:null,
				userdata:null,
				nombre:null,
			}
		},
		methods: {
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			},
			Salir(){
				Cookies.set("user","null") ;
				document.location.reload();
				
			}
		},
		mounted() {
			//  this.userdata =Cookies.get("user");
			this.useconver = Cookies.get("user");
			 

			if(this.useconver ==null || this.useconver == 'null' ){
				this.userdata=null;
				this.nombre=null;	
				
			}else{
				if(this.useconver != null || this.useconver !="null"){
				this.userdata=JSON.parse(this.useconver)
				 
				if(this.userdata != null || this.userdata != "null"){
					 this.nombre=this.userdata[0].nombre+' '+this.userdata[0].apellido_paterno+' '+this.userdata[0].apellido_materno;
				  
				}
				
				}		
			}
			// if(this.useconver != null || this.useconver !="null"){
			// 	this.userdata=JSON.parse(this.useconver)
				 
			// 	// if(this.userdata != null || this.userdata != "null"){
			// 	// 	 this.nombre=this.userdata[0].nombre+' '+this.userdata[0].apellido_paterno+' '+this.userdata[0].apellido_materno;
				  
			// 	// }
				
			// }
			 
			
		},
	}
</script>

<style scoped>

</style>