 <template>
<div class="p-grid p-fluid dashboard">
	<!-- <div class="p-col-12 p-lg-4">
		<div class="card summary">
			<span class="title">PAC</span>
			<span class="detail">Pacientes</span>
			<span class="count visitors">{{PacienteCount}}</span>
		</div>
	</div>
	<div class="p-col-12 p-lg-4">
		<div class="card summary">
			<span class="title">DOC</span>
			<span class="detail">Doctores</span>
			<span class="count purchases">{{DoctoresCount}}</span>
		</div>
	</div>
	

	<div class="p-col-12 p-md-6 p-xl-3">
		<div class="highlight-box">
			<div class="initials" style="background-color: #007be5; color: #00448f"><span>MU</span></div>
			<div class="highlight-details ">
				<i class="pi pi-search"></i>
				<span>Muestras</span>
				<span class="count">{{MuestrasCount}}</span>
			</div>
		</div>
	</div>
	<div class="p-col-12 p-md-6 p-xl-3">
		<div class="highlight-box">
			<div class="initials" style="background-color: #ef6262; color: #a83d3b"><span>EN</span></div>
			<div class="highlight-details ">
				<i class="pi pi-question-circle"></i>
				<span>Envíos Hoy</span>
				<span class="count">{{EnviadosCount}}</span>
			</div>
		</div>
	</div> -->
	<!-- <div class="p-col-12 p-md-6 p-xl-3">
		<div class="highlight-box">
			<div class="initials" style="background-color: #20d077; color: #038d4a"><span>RC</span></div>
			<div class="highlight-details ">
				<i class="pi pi-filter"></i>
				<span>Recepciones</span>
				<span class="count">21</span>
			</div>
		</div>
	</div> -->
	<!-- <div class="p-col-12 p-md-6 p-xl-3">
		<div class="highlight-box">
			<div class="initials" style="background-color: #f9c851; color: #b58c2b"><span>MC</span></div>
			<div class="highlight-details ">
				<i class="pi pi-check"></i>
				<span>Muestras Cerradas</span>
				<span class="count">{{MuestrasCerradasCout}}</span>
			</div>
		</div>
	</div> -->

	<!-- <div class="p-col-12 p-md-6 p-lg-4">
		<Panel header="Actividades Pendientes" style="height: 100%">
			<ul class='task-list'>
				<li>
					<Checkbox name="task" value="reports" v-model="tasksCheckbox" />
					<span class="task-name">Alimentar muestra 2342</span>
					<i class="pi pi-chart-bar" />
				</li>
				<li>
					<Checkbox name="task" value="invoices" v-model="tasksCheckbox" />
					<span class="task-name">Alimentar muestra 2343</span>
					<i class="pi pi-dollar" />
				</li>
				<li>
					<Checkbox name="task" value="dinner" v-model="tasksCheckbox" />
					<span class="task-name">Alimentar muestra 2344</span>
					<i class="pi pi-user" />
				</li>
				<li>
					<Checkbox name="task" value="meeting" v-model="tasksCheckbox" />
					<span class="task-name">Enviar muestra 2442</span>
					<i class="pi pi-users" />
				</li>
				<li>
					<Checkbox name="task" value="theme" v-model="tasksCheckbox" />
					<span class="task-name">Toma de muestra</span>
					<i class="pi pi-globe" />
				</li>
				<li>
					<Checkbox name="task" value="flight" v-model="tasksCheckbox" />
					<span class="task-name">Recambio de medios 873</span>
					<i class="pi pi-briefcase" />
				</li>
			</ul>
		</Panel>
	</div> -->

	<!-- <div class="p-col-12 p-md-6 p-lg-4 p-fluid contact-form">
		<Panel header="Mensaje Express">
			<div class="p-grid">
				<div class="p-col-12">
					<Dropdown v-model="dropdownCity" :options="dropdownDoctores" optionLabel="name" placeholder="Seleccionar Doctor" />
				</div>
				<div class="p-col-12">
					<InputText type="text" placeholder="Nombre" />
				</div>
				<div class="p-col-12">
					<InputText type="text" placeholder="Edad" />
				</div>
				<div class="p-col-12">
					<InputText type="text" placeholder="Message" />
				</div>
				<div class="p-col-12">
					<Button type="button" label="Send" icon="pi pi-envelope"/>
				</div>
			</div>
		</Panel>
	</div> -->

	<!-- <div class="p-col-12 p-lg-4 contacts" >
		<Panel header="Contactos">
			<ul v-for="(item,i) of Contactos" :key="i">
				<li >
					<button class="p-link"> 
						<span class="name">{{item.nombre}}</span>
						<span class="email">{{item.email}}</span> 
					</button>
				</li> -->
				<!-- <li>
					<button class="p-link">
						<img src="assets/layout/images/avatar_2.png" width="35" alt="avatar2"/>
						<span class="name">Osvaldo Vázquez</span>
						<span class="email">ovazquez@sg-cells.mx</span>
					</button>
				</li>
				<li>
					<button class="p-link">
						<img src="assets/layout/images/avatar_3.png" width="35" alt="avatar3"/>
						<span class="name">Jorge Lara</span>
						<span class="email">jlara@sg-cells.mx</span>
					</button>
				</li>
				<li>
					<button class="p-link">
						<img src="assets/layout/images/avatar_4.png" width="35" alt="avatar4"/>
						<span class="name">Juan Pérez</span>
						<span class="email">jperez@gmail.com</span>
					</button>
				</li> -->
			<!-- </ul>
		</Panel>
	</div> -->

	<!-- <div class="p-col-12 p-lg-6">
		<div class="card">
			<h1 style="font-size:16px">Recent Sales</h1>
			<DataTable :value="products" class="p-datatable-customers" :rows="5" style="margin-bottom: 20px" :paginator="true">
				<Column>
					<template #header>
						Logo
					</template>
					<template #body="slotProps">
						<img :src="'assets/layout/images/product/' + slotProps.data.image" :alt="slotProps.data.image" width="50" />
					</template>
				</Column>
				<Column field="name" header="Name" :sortable="true"></Column>
				<Column field="category" header="Category" :sortable="true"></Column>
				<Column field="price" header="Price" :sortable="true">
					<template #body="slotProps">
						{{formatCurrency(slotProps.data.price)}}
					</template>
				</Column>
				<Column>
					<template #header>
						View
					</template>
					<template #body>
                        <Button icon="pi pi-search" type="button" class="p-button-success p-mr-2 p-mb-1"></Button>
                        <Button icon="pi pi-times" type="button" class="p-button-danger p-mb-1"></Button>
					</template>
				</Column>
			</DataTable>
		</div>
	</div> -->
	<!-- <div class="p-col-12 p-lg-6">
		<div class="card">
			<Chart type="line" :data="lineData" />
		</div>
	</div> -->

	<!-- <div class="p-col-12 p-lg-8">
		<Panel header="Calendar" style="height: 100%">
			<FullCalendar :events="events" :options="options" />
		</Panel>
	</div> -->

	<!-- <div class="p-col-12 p-lg-4">
		<Panel header="Activity" style="height: 100%">
			<div class="activity-header">
				<div class="p-grid">
					<div class="p-col-6">
						<span style="font-weight:bold">Last Activity</span>
						<p>Updated 1 minute ago</p>
					</div>
					<div class="p-col-6" style="text-align: right">
						<Button label="Refresh" icon="pi pi-refresh" />
					</div>
				</div>
			</div>

			<ul class="activity-list">
				<li>
                    <div class="p-d-flex p-jc-between p-ai-center p-mb-3">
                        <h5 class="activity p-m-0">Income</h5>
                        <div class="count">$900</div>
                    </div>
                    <ProgressBar :value="95" :showValue="false" />
				</li>
				<li>
					<div class="p-d-flex p-jc-between p-ai-center p-mb-3">
						<h5 class="activity p-m-0">Tax</h5>
						<div class="count" style="background-color:#f9c851">$250</div>
					</div>
					<ProgressBar :value="24" :showValue="false" />
				</li>
				<li>
					<div class="p-d-flex p-jc-between p-ai-center p-mb-3">
						<h5 class="activity p-m-0">Invoices</h5>
						<div class="count" style="background-color:#20d077">$125</div>
					</div>
					<ProgressBar :value="55" :showValue="false" />
				</li>
				<li>
					<div class="p-d-flex p-jc-between p-ai-center p-mb-3">
						<h5 class="activity p-m-0">Expenses</h5>
						<div class="count" style="background-color:#f9c851">$250</div>
					</div>
					<ProgressBar :value="15" :showValue="false" />
				</li>
				<li>
					<div class="p-d-flex p-jc-between p-ai-center p-mb-3">
						<h5 class="activity p-m-0">Bonus</h5>
						<div class="count" style="background-color:#007be5">$350</div>
					</div>
					<ProgressBar :value="5" :showValue="false" />
				</li>
				<li>
					<div class="p-d-flex p-jc-between p-ai-center p-mb-3">
						<h5 class="activity p-m-0">Revenue</h5>
						<div class="count" style="background-color:#ef6262">$500</div>
					</div>
					<ProgressBar :value="25" :showValue="false" />
				</li>
			</ul>
		</Panel>
	</div> -->
	<div class="p-col-12 p-lg-6">
		<h3 class="title">Envíos Programados para HOY</h3>
		<div class="card summary" style="text-align:center">
			<DataTable :value="EnviosProgramadosHoy">
				<Column field="muestras" header="Muestras" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important"  ></Column>
				<Column field="celulas" header="Células" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column>
				<Column field="total" header="Total" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column> 
			</DataTable>
		</div>
	</div>
	<div class="p-col-12 p-lg-6">
		<h3 class="title">Recambio de Medios Programados para HOY</h3>
		<div class="card summary">
			<DataTable :value="RecambiosMediosProgramadoHoy">
				<Column field="folio" header="Folio" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column>
				<Column field="servicio" header="Servicio" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column> 
			</DataTable>
		</div>
	</div>

	<div class="p-col-12 p-lg-6">
		<h3 class="title">Detalle Envíos Programados para HOY</h3>
		<div class="card summary">
			<DataTable :value="DetalleEnviosProgramadosHoy">
				<Column field="folio" header="Folio" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column>
				<Column field="doctor" header="Doctor" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column>
				<Column field="tipo" header="Tipo" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column> 
			</DataTable>
		</div>
	</div>
	<div class="p-col-12 p-lg-6">
		<h3 class="title" style="color: red;">Recambio de Medios RETRASADOS</h3>
		<div class="card summary">
			<DataTable :value="RecambioMediosRetrazados">
				<Column field="folio" header="Folio" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column>
				<Column field="servicio" header="Servicio" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column> 
			</DataTable>
		</div>
	</div>

	<div class="p-col-12 p-lg-6">
		<h3 class="title" style="color: red;">Envíos RETRASADOS</h3>
		<div class="card summary">
			<DataTable :value="EnviosRetrazados">
				<Column field="muestras" header="Muestras" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column>
				<Column field="celulas" header="Células" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column>
				<Column field="total" header="Total" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column> 
			</DataTable>
		</div>
	</div>
	<div class="p-col-12 p-lg-6">
		<!-- <h3 class="title">Recambio de Medios RETRAZADOS</h3> -->
		<div class="card summary">
			<DataTable :value="TotalPorcentaje">
				<Column field="proceso" header="Muestras en Proceso" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column>
				<Column field="cerradas" header="Muestras Cerradas" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column> 
				<Column field="canceladas" header="Muestras Canceladas" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column> 
				<Column field="total" header="Total"></Column> 
			</DataTable>
		</div>
	</div>


	<div class="p-col-12 p-lg-6">
		<h3 class="title" style="color: red;">Detalle Envíos RETRASADOS</h3>
		<div class="card summary">
			<DataTable :value="DetalleEnviosProgramadosRetrazados">
				<Column field="folio" header="Folio" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column>
				<Column field="doctor" header="Doctor" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column>
				<Column field="tipo" header="Tipo" headerStyle="text-align:center !important"  bodyStyle="text-align:center !important" ></Column> 
			</DataTable>
		</div>
	</div>
	 
</div>
</template>

<script>
import ProductService from '../service/ProductService';
import EventService from '../service/EventService';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import DashboardService from '../service/DashboardService';

import Cookies from "js-cookie"
window.Cookies = Cookies
export default {
	data() {
		return {
			tasksCheckbox: [],
			dropdownDoctores: [
				{name: 'Juan Rodriguez', code: 'NY'},
				{name: 'Mario Moreno', code: 'RM'},
				{name: 'Laura Rincón', code: 'LDN'},
				{name: 'Matías García', code: 'IST'},
				{name: 'Luis López', code: 'PRS'}
			],
			dropdownCity: null,
			options: {
				plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
				defaultDate: '2019-01-01',
				header: {
					left: 'prev,next',
					center: 'title',
					right: 'dayGridMonth,timeGridWeek,timeGridDay'
				},
				editable: true
			},
			events: null,
			products: null,
			selectedProducts: null,
			lineData: {
				labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				datasets: [
					{
						label: 'First Dataset',
						data: [65, 59, 80, 81, 56, 55, 40],
						fill: false,
						backgroundColor: '#2f4860',
						borderColor: '#2f4860'
					},
					{
						label: 'Second Dataset',
						data: [28, 48, 40, 19, 86, 27, 90],
						fill: false,
						backgroundColor: '#00bb7e',
						borderColor: '#00bb7e'
					}
				]
			},
			userdata:null,
			PacienteCount:0,
			DoctoresCount:0,
			MuestrasCount:0,
			EnviadosCount:0,
			MuestrasCerradasCout:0,
			Contactos:null,
			EnviosProgramadosHoy:[],
			RecambiosMediosProgramadoHoy:[],
			DetalleEnviosProgramadosHoy:[],
			RecambioMediosRetrazados:[],
			EnviosRetrazados:[],
			TotalPorcentaje:[],
			DetalleEnviosProgramadosRetrazados:[],
		}
	},
	productService: null,
	eventService: null,
	dashboardservice:null,
	created() {
		this.productService = new ProductService();
		this.eventService = new EventService();
		this.dashboardservice = new DashboardService();
	},
	mounted() {
		this.productService.getProductsSmall().then(data => this.products = data);
		this.eventService.getEvents().then(data => this.events = data);

		this.dashboardservice.getpacientedash().then(data => {
			this.PacienteCount=data.Paciente;
			this.DoctoresCount=data.Doctores;
			this.MuestrasCount=data.Muestras;
			this.EnviadosCount=data.EnvioMuestras; 
			this.MuestrasCerradasCout=data.MuestrasCerradas; 
			this.Contactos=data.usuarios; 
			this.EnviosProgramadosHoy.push({
				"muestras":data.muestrasenviohoy ,
				"celulas":data.celulasenviohoy,
				"total":data.muestrasenviohoy+data.celulasenviohoy
			});
		 				 
			for(var i in data.recambiomedios){
				this.RecambiosMediosProgramadoHoy.push({
					"folio":data.recambiomedios[i].id_muestra ,
					"servicio":data.recambiomedios[i].nombre,
				});
			}
			
			for(var ij in data.detalleenvíosprogramadosm){
				this.DetalleEnviosProgramadosHoy.push({
					"folio":data.detalleenvíosprogramadosm[ij].id_muestra ,
					"doctor":data.detalleenvíosprogramadosm[ij].nombre,
					"tipo": "Muestra",
				});
			}

			for(var ik in data.detalleenvíosprogramadosc){
				this.DetalleEnviosProgramadosHoy.push({
					"folio":data.detalleenvíosprogramadosc[ik].id_muestra ,
					"doctor":data.detalleenvíosprogramadosc[ik].responsable_envio,
					"tipo": "Célula",
				});
			}

			for(var ij1 in data.detalleenvíosprogramadosmRetrasados){
				this.DetalleEnviosProgramadosRetrazados.push({
					"folio":data.detalleenvíosprogramadosmRetrasados[ij1].id_muestra ,
					"doctor":data.detalleenvíosprogramadosmRetrasados[ij1].nombre,
					"tipo": "Muestra",
				});
			}

			for(var ik2 in data.detalleenvíosprogramadoscRetrasados){
				this.DetalleEnviosProgramadosRetrazados.push({
					"folio":data.detalleenvíosprogramadoscRetrasados[ik2].id_muestra ,
					"doctor":data.detalleenvíosprogramadoscRetrasados[ik2].responsable_envio,
					"tipo": "Célula",
				});
			}
			
			for(var il in data.recambiomediosretrazados){
				this.RecambioMediosRetrazados.push({
					"folio":data.recambiomediosretrazados[il].id_muestra ,
					"servicio":data.recambiomediosretrazados[il].nombre,
				});
			}

			this.EnviosRetrazados.push({
				"muestras":data.muestrasenvioretrazados ,
				"celulas":data.celulasenvioretrazados,
				"total":data.muestrasenvioretrazados+data.celulasenvioretrazados
			});

			this.TotalPorcentaje.push({
				"proceso":data.muestrasproceso,
				"cerradas":data.muestrascerradas,
				"canceladas":data.muestrascanceladas,
				"total":data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas
			});


			var procesoT= (data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas == 0)? 0:((data.muestrasproceso*100)/(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas)).toFixed(2);
			var cerradasT =(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas ==0)?0:((data.muestrascerradas*100)/(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas)).toFixed(2);
			var canceladasT=(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas ==0)?0:((data.muestrascanceladas*100)/(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas)).toFixed(2);
			var total=(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas == 0) ?0 :((data.muestrasproceso*100)/(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas))+((data.muestrascerradas*100)/(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas))+((data.muestrascanceladas*100)/(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas));


			this.TotalPorcentaje.push({
				"proceso":procesoT+' %',
				"cerradas":cerradasT+' %',
				"canceladas":canceladasT+' %',
				"total":total+' %'
			});

			// this.TotalPorcentaje.push({
			// 	"proceso":  ((data.muestrasproceso*100)/(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas)).toFixed(2)+' %',
			// 	"cerradas":((data.muestrascerradas*100)/(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas)).toFixed(2)+' %',
			// 	"canceladas":((data.muestrascanceladas*100)/(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas)).toFixed(2)+' %',
			// 	"total":((data.muestrasproceso*100)/(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas))+((data.muestrascerradas*100)/(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas))+((data.muestrascanceladas*100)/(data.muestrasproceso+data.muestrascerradas+data.muestrascanceladas))+' %'
			// });
			
			 
		});

		let afId = this.$route.query['af_id'];
        if (afId) {
            let today = new Date();
            let expire = new Date();
            expire.setTime(today.getTime() + 3600000*24*7);
            document.cookie = 'primeaffiliateid=' + afId + ';expires=' + expire.toUTCString() + ';path=/; domain:primefaces.org';
		}
		this.userdata =Cookies.get( "user" );
		if(this.userdata==null || this.userdata=='null'){
			this.$router.replace({ name: "login" });
		}else{
			this.$router.replace({ name: "dashboard" });
		}
	},
	methods: {
		formatCurrency(value) {
			return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
		}
	}
}
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 960px) {
		::v-deep(.p-datatable) {
			&.p-datatable-customers {
				.p-datatable-thead > tr > th,
				.p-datatable-tfoot > tr > td {
					display: none !important;
				}

				.p-datatable-tbody > tr {
					border-bottom: 1px solid #dee2e6;
					> td {
						text-align: center !important;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 0 none !important;
						width: 100% !important;
						float: left;
						clear: left;
						border: 0 none;

						.p-column-title {
							padding: .4rem;
							min-width: 30%;
							display: inline-block;
							margin: -.4rem 1rem -.4rem -.4rem;
							font-weight: bold;
						}

						.p-progressbar {
							margin-top: .5rem;
						}
					}
				}
				 
			}
		}
	}
.dashboard .contacts ul li button{
	padding:30px;
}


 
</style>
<style scoped>
.p-datatable .p-datatable-thead > tr > th{
	text-align: center !important;
}
.p-datatable .p-datatable-thead > tr{
	text-align: center !important;
}
.p-datatable .p-datatable-tbody > tr > td{
	text-align: center !important;
}
 

</style>
