<template>
    <div id="login">
        <div class="login-body">
            <div class="body-container">
                <div class="p-grid crud-demo">
                    <div class="p-col-6 p-offset-3">
                        <Toast/>
                        <div style="text-align:center;margin-bottom:30px">
                             <img src="assets/layout/images/sg.png" style="width:40%"/>
                        </div>
                       <div style="text-align:center">
                        <!-- <h1>SAM</h1> -->
                        <h3>SAM - Sistema de Administración de Muestras</h3>
                        <p>Favor de ingresar su usuario y contraseña</p>
                        <!-- <h4>Portal para consulta de muestras</h4> -->
                        </div>
                    </div>
                    <div class="p-sm-12 p-md-12 p-lg-6 p-xl-6 p-col-offset-3 p-sm-offset-3 p-md-offset-3 p-lg-offset-3 p-xl-offset-3">
                        <div class="login-wrapper" >
                            <div class="login-container" >
                                
                                <div class="p-grid p-fluid" >
                                    <div class="p-col-4 p-offset-4  p-col-offset-4 p-sm-offset-4 p-md-offset-4 p-lg-offset-4" >
                                        <input class="p-inputtext p-component" v-model="input.username" placeholder="Usuario" v-on:keyup.enter="login" >
                                    </div>
                                    <div class="p-col-4 p-offset-4  p-col-offset-4 p-sm-offset-6 p-md-offset-4 p-lg-offset-4" >
                                        <input class="p-inputtext p-component" type="password" v-model="input.password" placeholder="Contraseña" v-on:keyup.enter="login">
                                    </div>
                                    <div class="p-col-4 p-offset-4  p-col-offset-4 p-sm-offset-4 p-md-offset-4 p-lg-offset-4" >
                                        <button class="p-button p-component" type="button" @click="login" >
                                            <span class="pi pi-check p-button-icon p-button-icon-left"></span>
                                            <span class="p-button-label">Entrar</span><!---->
                                            <span class="p-ink"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>
</template>

<script>
import LoginService from '../service/LoginService';
import UsuarioService from '../service/UsuarioService';
import Cookies from "js-cookie"
window.Cookies = Cookies

export default {
    name: 'Login',
    props: ['user'],
	data() {
		return {
            input: {
                username: "",
                password: ""
            },
            loginpost:[],
            responselog:null,
        }
    },
    loginservice:null,
    usuarioService: null,
    created(){
        this.loginservice = new LoginService();
        this.usuarioService = new UsuarioService();
    },
    mounted() {
        this.userdata =Cookies.get( "user" );
		if(this.userdata==null || this.userdata=='null'){
            this.$router.push({ path: '/login' });   
            this.layoutMode='static';
            
		}else{
            this.layoutMode='overlay';
            this.$router.replace({ name: "dashboard" });
            this.$router.push({ path: 'dashboard' });
            
            // document.location.reload();
		}
    },
    methods: {
        login() {
            if(this.input.username != "" && this.input.password != "") {
                this.loginpost.push({
                    'nombre':this.input.username,
                    'contrasena':this.input.password
                });
                console.log(this.loginpost);
                 this.loginservice.loginUsuarios( this.loginpost[0]).then(data =>{ 
                     this.responselog=data;
                    //  console.log(this.responselog)
                    if(this.responselog[0] != null || this.responselog[0]==[]){
                        
                        
                        this.loginpost=[];
                         Cookies.set("user",data) ;
                        //   document.location.reload();
                         this.usuarioService.getUsuariosPantallaInActivo(data[0]).then(data => {
                            //  console.log(data);
                             localStorage.setItem('permisos', JSON.stringify(data))
                            //  var json_str = JSON.stringify(data);
                            //  console.log(json_str);
                            //  Cookies.set('userpers',{json_str});
                            // document.cookie =('userpers='+JSON.stringify(data));
                            //  Cookies.set('pantall',JSON.stringify(data));
 
                            // Cookies.set("userpers",data) ;
                            // Cookies.set("userpers1",serialize(data)) ;
                             document.location.reload();
                        });
                        
                        // this.$toast.add({severity:'success', summary: 'Successful', detail: 'dato', life: 3000});
                    }else{
                        this.loginpost=[];
                      
                        Cookies.set("user","null") ;
                         this.$toast.add({severity:'error', summary: 'Error', detail: 'El usuario o contraseña son incorrectos', life: 3000});
                    }
			    });
            } else {
                console.log("Se debe teclear el usuario y contraseña");
                this.$toast.add({severity:'error', summary: 'Error', detail: 'Se debe teclear el usuario y contraseña', life: 3000});
            }
        },
       
    }

}

</script>

<style scoped lang="scss">

</style>